//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {Message} from "element-ui";
import {fetchMarketingCheckWorkList} from "@/api/checkWork";
import {fetchFilter} from "@/api/master";
import func from "@/utils/decoded";
import moment from "moment/moment";

const fields = [
	{ key: 'contract_id', label:'เลขที่สัญญา', _style:'min-width:100px' },
	{ key:'name_customer', label:'ชื่อ-สกุล', _style:'min-width:150px;'},
	{ key: 'start_date_contract', label:'วันที่เริ่มทำสัญญา', _style:'min-width:100px;' },
	{ key: 'balance', label:'ยอดเงินคงเหลือ', _style:'min-width:100px; text-align: right;' },
  { key: 'step', label:'ขั้นตอน', _style:'min-width:100px;' },
  { key: 'status_id', label:'สถานะ', _style:'min-width:100px;' },
  { key: 'process_day', label:'เวลาดำเนินการ', _style:'min-width:50px; text-align: right;' },
	{
		key: 'show_details',
		label: 'รายละเอียด',
		_style: 'min-width:150px; text-align: center;',
		sorter: false,
		filter: false
	}
]

const items = []

export default {
  name: 'CheckWork',
  created() {
    document.title = this.$route.name + " : ONE Tracemoney"
  },
  data() {
    return {
      searchParams: {
        keyword: '',
        startDate: '',
        endDate: '',
        filter: 0
      },
      options: ['ทั้งหมด','รอดำเนินการ 0-15 วัน','รอดำเนินการ 16-30 วัน','รอดำเนินการ 31-45 วัน','รอดำเนินการมากกว่า 45 วัน','เรียบร้อย','ไม่เรียบร้อย'],
      show: true,
			isCollapsed: true,
			loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
			items: items.map((item, id) => { return {...item, id}}),
			fields,
      filterParams: {
        stepId: 1
      },
			details: [],
			collapseDuration: 0
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  async mounted() {
    this.$store.state.loading = true
    try {
			this.searchParams.endDate = moment().format('YYYY-MM-DD')
			this.searchParams.startDate = moment().subtract(6, 'months').format('YYYY-MM-DD')
      await new Promise((resolve, reject) => {
        fetchFilter(this.filterParams).then(response => {
          const check = response.header
          const data = response.body
          if (check.error === 'N') {
            this.options = data
            resolve()
          }
        }).catch(error => {
          this.errorMsg = error
          console.log(error)
          reject(error)
        })
      })
      await this.getMarketingWork()
    } finally {
      this.$store.state.loading = false
    }

    const check = func.checkMenu(this.$route.path)
    if (check && check.length > 0) {
      this.privilege = check[0]
    }
  },
  methods: {
    async getMarketingWork() {
      await fetchMarketingCheckWorkList(this.searchParams).then(res => {
        this.items = []
        const check = res.header;
        const data = res.body;
        if (check.error == 'N') {
          for (let item of data) {
            switch (item.cur_step) {
              case 1: item.step = 'ตรวจสอบเอกสาร'; break
              case 2: item.step = 'วางชุดโอน'; break
              case 3: item.step = 'รับเล่มทะเบียน'; break
              case 4: item.step = 'รอโอนรถ'; break
              case 5: item.step = 'โอนเงินสินเชื่อ'; break
              default: item.step = ''
            }
            if (item.status_id == 2) {
              item.status = 'เรียบร้อย'
            } else if (item.status_id == 1) {
              item.status = 'ไม่เรียบร้อย'
            } else {
              item.status = 'รอดำเนินการ'
            }
            this.items.push(item)
          }
        }
      }).catch(error => {
        Message({
          message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
          type: 'error',
          duration: 5 * 1000,
        })
        this.errorMsg = error
        console.log(error)
      })
    },
    getStepColor (step) {
      switch (step) {
        case 1: return 'success'
        case 2: return 'info'
        case 3: return 'primary'
        case 4: return 'warning'
        case 5: return 'danger'
        default: return 'secondary'
      }
    },
    getBadge (status) {
			switch (status) {
				case 1: return 'danger'
				case 2: return 'success'
				default: return 'warning'
			}
		},
    rowClickDetail(item, index){
      console.log(item.contractNumber);
      this.$router.push({path: `/marketing/CheckWorkDetail/${item.contract_id}`})
    },
    pageChange (val) {
      console.log(val);
      this.$router.push({ query: { page: val }})
    }
  }
}
