var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("วางชุดโอน")])]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"12","md":"2"}},[_c('CInput',{attrs:{"label":"ค้นหา"},model:{value:(_vm.searchParams.keyword),callback:function ($$v) {_vm.$set(_vm.searchParams, "keyword", $$v)},expression:"searchParams.keyword"}})],1),_c('CCol',{attrs:{"sm":"12","md":"2"}},[_c('CInput',{attrs:{"type":"date","label":"วันที่เริ่มสัญญา (จาก)"},model:{value:(_vm.searchParams.startDate),callback:function ($$v) {_vm.$set(_vm.searchParams, "startDate", $$v)},expression:"searchParams.startDate"}})],1),_c('CCol',{attrs:{"sm":"12","md":"2"}},[_c('CInput',{attrs:{"type":"date","label":"วันที่เริ่มสัญญา (ถึง)"},model:{value:(_vm.searchParams.endDate),callback:function ($$v) {_vm.$set(_vm.searchParams, "endDate", $$v)},expression:"searchParams.endDate"}})],1),_c('CCol',{attrs:{"sm":"12","md":"3"}},[_c('CSelect',{attrs:{"label":"สถานะ","options":_vm.options},model:{value:(_vm.searchParams.filter),callback:function ($$v) {_vm.$set(_vm.searchParams, "filter", $$v)},expression:"searchParams.filter"}})],1),_c('CCol',{attrs:{"sm":"12","md":"3"}},[_c('CButton',{staticClass:"bTitle mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.getMarketingWork()}}},[_c('CIcon',{attrs:{"name":"cil-search"}}),_vm._v(" ค้นหา")],1),_c('CButton',{staticClass:"bTitle",attrs:{"color":"primary"}},[_c('CIcon',{attrs:{"name":"cil-file"}}),_vm._v(" ดาวโหลด (Excel)")],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"items-per-page":10,"hover":"","sorter":"","pagination":""},on:{"page-change":_vm.pageChange},scopedSlots:_vm._u([{key:"balance",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.balance)+" ")])]}},{key:"step",fn:function(ref){
var item = ref.item;
return [_c('td',{class:("text-" + (_vm.getStepColor(item.cur_step)))},[_vm._v(" "+_vm._s(item.step)+" ")])]}},{key:"status_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.status_id)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"process_day",fn:function(ref){
var item = ref.item;
return [(item.process_day <= 45)?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.process_day)+" วัน ")]):_vm._e(),(item.process_day > 45)?_c('td',{staticClass:"py-2 text-center text-danger"},[_vm._v(" "+_vm._s(item.process_day)+" วัน ")]):_vm._e()]}},{key:"show_details",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.rowClickDetail(item, index)}}},[_c('CIcon',{attrs:{"name":"cil-pen"}})],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }